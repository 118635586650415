.chart-sub-value-container {
    text-align: left;
    font: normal normal 600 24px/40px 'Source Sans 3';
    letter-spacing: -0.38px;
    opacity: 1;
}

.chart-sub-title-container {
    text-align: left;
    font: normal normal normal 12px/17px "Signika";
    letter-spacing: 2.4px;
    color: #fff;
    text-transform: uppercase;
    opacity: .5;
}