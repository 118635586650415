@tailwind base;

@tailwind components;

@tailwind utilities;


@font-face {
    font-family: 'Basic Sans';
    src: url('./fonts/basicsanssf.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Basic Sans';
    src: url('./fonts/basicsanssfbold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Basic Sans';
    src: url('./fonts/basicsanssfitalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Basic Sans Light';
    src: url('./fonts/basicsanslightsfregular.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

.custom-button {
    position: relative;
    background-color: #12151f; /* Dark blue background */
    color: #ff645a; /* Custom red text color */
    padding: 8px 16px; /* Padding around text */
    border: none; /* No border */
    cursor: pointer; /* Pointer cursor on hover */
}

.corner {
    position: absolute;
    width: 10px; /* Width of the corner */
    height: 10px; /* Height of the corner */
    border-style: solid;
    border-color: #ffffff; /* Color of the border */
}

.top-left {
    top: 0;
    left: 0;
    border-width: 2px 0 0 2px; /* Right and bottom borders are transparent */
}

.top-right {
    top: 0;
    right: 0;
    border-width: 2px 2px 0 0; /* Bottom and left borders are transparent */
}

.bottom-left {
    bottom: 0;
    left: 0;
    border-width: 0 0 2px 2px; /* Top and right borders are transparent */
}

.bottom-right {
    bottom: 0;
    right: 0;
    border-width: 0 2px 2px 0; /* Top and left borders are transparent */
}



body {
    font-family: 'Basic Sans', sans-serif;
    background-color: #010622;
}

.all-caps {
    font: normal normal 600 14px/17px 'Source Sans 3';
    letter-spacing: 2.8px;
    text-transform: uppercase;
}

/* Apply to the entire app or a specific container */
body, html {
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #FF6467 #010622; /* For Firefox */
}

/* For WebKit-based browsers (Chrome, Safari, etc.) */
::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
    background: #010622; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
    background-color: #FF6467; /* Scrollbar color */
    border-radius: 10px; /* Round edges of the scrollbar */
    border: 3px solid #010622; /* Space around the scrollbar */
}

/* Optional: Hover effect on the scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
    background-color: #FF8082; /* Lighter color on hover */
}

@font-face {
    font-family: "flaticon_bh_social_logos";
    src: url("./fonts/flaticon_bh_social_logos.woff2?c41a353c1d090ab98cabe08b929bd099") format("woff2"),
url("./fonts/flaticon_bh_social_logos.woff?c41a353c1d090ab98cabe08b929bd099") format("woff"),
url("./fonts/flaticon_bh_social_logos.eot?c41a353c1d090ab98cabe08b929bd099#iefix") format("embedded-opentype"),
url("./fonts/flaticon_bh_social_logos.ttf?c41a353c1d090ab98cabe08b929bd099") format("truetype"),
url("./fonts/flaticon_bh_social_logos.svg?c41a353c1d090ab98cabe08b929bd099#flaticon_bh_social_logos") format("svg")
}


i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon_bh_social_logos !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.flaticon-bh-caret-down-squared:before {
    content: "\f101";
}
.flaticon-bh-twitter:before {
    content: "\f102";
}
.flaticon-bh-medium:before {
    content: "\f103";
}
.flaticon-bh-discord:before {
    content: "\f104";
}
.flaticon-bh-telegram:before {
    content: "\f105";
}
.flaticon-bh-caret-up-squared:before {
    content: "\f106";
}
.flaticon-bh-medium-ugly:before {
    content: "\f107";
}