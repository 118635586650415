

  .gradient-border-box {
    background: transparent linear-gradient(180deg, rgba(0, 0, 0, .05), hsla(0, 0%, 100%, .05)) 0 0 no-repeat padding-box;
    border: 1px solid transparent;
    overflow: hidden;
    -o-border-image: linear-gradient(180deg, rgba(53, 136, 116, .8), rgba(49, 69, 105, 0)) 1;
    border-image: linear-gradient(180deg, rgba(53, 136, 116, .8), rgba(49, 69, 105, 0)) 1;
    border-image-slice: 1;
}

  .network-name{
    font: normal normal normal 24px / 29px 'Signika';
    letter-spacing: 0;
    opacity: .75;
}

.network-value {
    font: normal normal 600 15px / 17px 'Source Sans 3';
    letter-spacing: 0;
    color: #fff;
    opacity: .75;
}

.network-button{
    font: normal normal 600 14px / 17px 'Source Sans 3';
    letter-spacing: 0;
    color: #fff;
    letter-spacing: 2.8px;
}

.network-apr{
    font: normal normal 300 15px / 18px Signika;
    letter-spacing: 2.8px;
    opacity: .5;
}

.page-text {
  text-transform: uppercase;
  font: normal normal bold 15px / 18px Signika;
  letter-spacing: 2.8px;
}

.page-text span {
  opacity: .5;
}

@media (max-width: 768px) {
  .empty-row {
    display: none;
  }
}


@media (max-width: 600px) {
    .network-name{
        font-size: 18px;
        line-height: 22px;
    }

    .network-value, .network-apr, .network-button{
        font-size: 12px;
        line-height: 14px;
    }
}
  
  .content {
    position: relative;
    z-index: 1;
    padding: 20px;
  }
  
